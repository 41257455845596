
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";

import Header from "@/components/header.vue";
import { onMounted, ref, reactive } from "vue";
import { getTenderDetail, submitTender } from "@/service/discover";
import router from "@/router";
import { openTips, openModal } from "@/utils/alert";
import { nameRegular, phoneRegular } from "@/utils/regular";
import { userinfo, checklogin } from "@/utils/usermodule";
import { getDate, getDateTime } from "@/utils/moment";

export default {
  name: "TenderDetail",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const {
      currentRoute: {
        value: {
          query: { id },
        },
      },
    } = router as any;
    const detail: any = ref([]);
    const form: any = reactive({
      name: "",
      companyName: "",
      phone: "",
      introduce: "",
    });

    const getDetail = async () => {
      const result = await getTenderDetail({
        code: id,
      });
      console.log(result);
      detail.value = result;
    };

    const submit = async () => {
      //检测是否登录
      if (!checklogin()) {
        return;
      }

      if (nameRegular(form.name)) {
        openTips("姓名输入有误!");
        return;
      }
      if (nameRegular(form.companyName)) {
        openTips("企业名称输入有误!");
        return;
      }
      if (phoneRegular(form.phone)) {
        openTips("企业联系电话输入有误!");
        return;
      }
      if (form.introduce === "" || form.introduce.length > 100) {
        openTips("投标介绍内容有误!");
        return;
      }
      const result = await submitTender({
        TenderId: id,
        LinkManMobile: form.phone,
        TenderDoc: form.introduce,
        LinkMan: form.name,
        TenderSupplierName: form.companyName,
        //SupplierId: '458ca3b1-2952-4580-ac95-ef7847597a8f',
        // 'Remark': '备注'
      });
      if (result) {
        openModal(`您已成功投标「${detail.value.tenderName}」`);
        form.name = "";
        form.companyName = "";
        form.phone = "";
        form.introduce = "";
      }
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      getDetail();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return { detail, form, submit, getDate, getDateTime };
  },
};
